<template>
  <iframe
    v-if="'youtube' in video"
    :src="`https://www.youtube.com/embed/${video.youtube}`"
    frameborder="0"
    allowfullscreen="1"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    title="YouTube video player"
    :width="width"
    :height="height"
  ></iframe>
</template>
<script>
export default {
  props: {
    video: Object,
    width: {
      type: Number,
      default: 640,
    },
    height: {
      type: Number,
      default: 390,
    },
  },
};
</script>
